import {useEffect, useState} from 'react';
import {apiClient} from '@common/http/query-client';

export interface Order {
  id: number;
  total_amount: number;
  status: string;
  created_at: string;
  payment_method: string;
  payment_source: string;
  card_number_last4?: string;
  currency_symbol: string;
  customer_name: string;
  customer_email: string;
  customer_avatar: string;
  brand_name: string;
}

interface OrderResponse {
  orders: Array<Order>,
  links: {
    first: null | string;
    last: null | string;
    prev: null | string;
    next: null | string;
  },
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    next_page: number | null;
    per_page: number;
    prev_page: number | null;
    to: number;
    total: number;
  }
}

export interface OrderTotals {
  all?: number;
  Succeeded?: number;
  refunded?: number;
  disputed?: number;
  failed?: number;
}

export default function useOrder(perPage: number = 10) {
  const [isPending, setIsPending] = useState<boolean>(true)
  const [isPendingOrderStatusTabs, setIsPendingOrderStatusTabs] = useState<boolean>(true)
  const [orders, setOrders] = useState<Order[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [error, setError] = useState<string | null>(null);
  const [totals, setTotals] = useState<OrderTotals>({})

  // Fetch initial data on mount
  useEffect(() => {
    getOrderStatusTabs()
  }, []);
  useEffect(() => {
    fetch(currentPage);
  }, [currentPage]);

  async function fetch (page: number = 1, perPageOverride?: number, filter?: object) {
    setIsPending(true);
    setError(null);
    try {
      const params = new URLSearchParams({
        'per-page': String(perPageOverride ?? perPage),
        page: String(page),
      }).toString();

      const response = await apiClient.get(`orders?${params}`, {
        params: {filter},
      });
      const data: OrderResponse = response.data;
      setOrders(data.orders);
      setCurrentPage(data.meta.current_page);
      setTotalPages(data.meta.last_page);
    } catch (err) {
      setError('Failed to fetch payment orders.');
      console.error(err);
    } finally {
      setIsPending(false);
    }
  }

  async function getOrderStatusTabs () {
    setIsPendingOrderStatusTabs(true);

    const response = await apiClient.get('get-order-status-tabs');
    const _data = response.data
    delete _data['seo']
    delete _data['status']

    setTotals(_data)
    setIsPendingOrderStatusTabs(false)
  }

  const setPage = (page: number) => {
    setCurrentPage(page);
  };

  return {
    isPendingOrderStatusTabs,
    isPending,
    totals,
    orders,
    currentPage,
    totalPages,
    error,
    setPage,
  }
}
